/**
 *
 * Header
 *
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { actions } from '../../containers/Landing/slice';

import { MenuItem } from './components/MenuItem';
import { MobileNavBar } from './components/MobileNavBar';
import { UserProfile } from './components/UserProfile';
import { SearchModal } from './components/SearchModal';
import { CalculusModal } from './components/CalculusModal';
import { endpoints } from '../../../constants/endpoints';
import { sendEvent } from '../../../utils/sendEvent';
import InterviewCalendar from '../../containers/InterviewCalendarHeader/index';
import navItems from './navItems';
import { productItems } from './productItems';
import { Button } from '../Button';
import { SideMenu } from '../SideMenu';

import './styles.scss';
import { useSelector } from 'react-redux';
import { selectPostJobVersion } from '../../selector';
import { logout } from '../../../services/logout';
import { fetchFeatureAccess } from '../../../services/fetchFeatureAccess';
import { dynamicallyLoad, getFromStorage, setStorage } from '../../../utils/helpers';
import { LoaderPropmpt } from '../DomainMigrationPrompts/LoaderPrompt';
import { HeaderPropmpt } from '../DomainMigrationPrompts/HeaderPrompt';
import { ContactSales } from './components/callSales';
import { MobileNavBarSignUp } from './components/MobileNavBarSignup';

export function Header(props) {
    // console.log(props.pathName.includes("contact-us"))
    // console.log(props.pathName)
    const [showSearch, setShowSearch] = useState(false);
    const [showNav, setShowNav] = useState(false);
    const [showNavMobileSignup, setShowNavMobileSignup] = useState(false);
    const [searchStr, setSearchStr] = useState('');
    const [searchModal, setSearchModal] = useState(false);
    const [calculusModal, setCalculusModal] = useState(false);
    const [contactMenuOpen, setContactMenuOpen] = useState(false);
    const [showMenuItems, setShowMenuItems] = useState({
        'Manager Portal': false,
        'Career Assist': false,
    });

    const[showTalentCloud, setShowTalentCloud] = useState(false)

    const {
        recruiterId,
        profile,
        checkFeature,
        useNewLandingDesign,
        noSignUp,
    } = props;

    const email = profile ? profile.email : null;

    useEffect(() => {
        const handleFeatureAccess = async () => {
            try {
                const currentTime = new Date().getTime();
                const featureAvailable =
                    JSON.parse(getFromStorage('feature-access')) || null;
                if (
                    !featureAvailable ||
                    currentTime > featureAvailable.expiry
                ) {
                    const res = await fetchFeatureAccess(recruiterId, {
                        email: email,
                    });

                    setShowMenuItems(prev => ({
                        ...prev,
                        'Manager Portal': res.data.data.showManagerPortal,
                        'Career Assist': res.data.data.showCareerAssist,
                    }));

                    const expiryTime = new Date().getTime() + 60 * 60 * 1000;
                    setStorage('feature-access', {
                        expiry: expiryTime,
                        ...res.data.data,
                    });
                } else {
                    setShowMenuItems(prev => ({
                        ...prev,
                        'Manager Portal': featureAvailable.showManagerPortal,
                        'Career Assist': featureAvailable.showCareerAssist,
                    }));
                }
            } catch (error) {
                console.log('fetch feature access error');
                console.log(error);
            }
        };

        if (email && recruiterId) {
            handleFeatureAccess();
        }

        if(checkFeature('talentCloud')) {
            if(checkFeature('domainMigrationPrompt1')){
                loadTalentCloudWidget("123px");
            }
            else{
                loadTalentCloudWidget();
            }
        }
    }, [email, recruiterId]);

    const loadTalentCloudWidget = (talentHeight) =>{
        dynamicallyLoad(window.widgets.talentCloudWidgetUrl, () => {
            window.talentCloud.init({
                rootSelector: "#talentCloud",
                gnbHeight: talentHeight || "75px",
                gnbTheme: "DARK",
                tenantId: parseInt(window.tenantId),
                companyId: profile.companyId ? parseInt(profile.companyId) : null,
                recruiterId: recruiterId,
                username: profile.name,
                email: profile.email,
                pageName: "Naukri Home Page",
                jwtCookieUrl:
                    `${process.env.REACT_APP_BASE_URL}/recruiter/${recruiterId}/talentCloud/login`,
                tenantDomainUrl:
                    `${process.env.REACT_APP_BASE_URL}/${talentCloudRollOutStatus}`
            }).then(
                function(result){
                    if(result){
                        setShowTalentCloud(true)
                    }
                    else{
                        setShowTalentCloud(false)
                    }
    
                }
            ).catch(
                function(error){
                    console.log(error)
                    setShowTalentCloud(false)
                }
            )
        })
    }

    const dispatch = useDispatch();

    const postJobVersion = useSelector(selectPostJobVersion);
    // ga analytics labels
    const logoLabel = `origin=Logo,recId=${recruiterId}`;
    const dashboadLabel = `origin=Dashboard,recId=${recruiterId}`;
    const myjobsLabel = `origin=NavBar,recId=${recruiterId}`;
    let postJobsLabel = `origin=NavBar,recId=${recruiterId}`;
    const manageCalLabel = `origin=NavBar,recId=${recruiterId}`;
    const searchLabel = `recid=${recruiterId},origin=Navbar,plan=${
        profile && +profile.plan ? 1 : 0
    }`;

    const calculusLabel = `recid=${recruiterId},origin=Navbar,plan=${
        profile && +parseInt(profile.calculus) ? 1 : 0
    }`;

    if (checkFeature('postJobReact')) {
        const postJobForm = postJobVersion
            ? postJobVersion == 1
                ? 'old'
                : postJobVersion == 2
                ? 'new'
                : ''
            : '';
        postJobsLabel += `,formtype=${postJobForm}`;
    }

    function onChange(ev) {
        setSearchStr(ev.target.value);
    }

    function submitSearch(ev) {
        ev.preventDefault();
        window.location = `/search?searchQuery=${encodeURI(searchStr)}`;
        return false;
    }

    // nav item click handler
    function navItemClick(key) {
        switch (key) {
            case 'search':
                sendEvent('clickSearchResume', searchLabel);
                if (parseInt(profile.search) === 1) {
                    return window.open(endpoints.searchUrl, '_blank');
                }
                setSearchModal(true);
                break;
            case 'dashboard':
                sendEvent('viewDashboard', dashboadLabel);
                break;
            case 'myjobs':
                sendEvent('myJobsClick', myjobsLabel);
                break;
            case 'postjob':
                sendEvent('postJobViewClick', postJobsLabel);
                break;
            case 'managecal':
                sendEvent('viewManageCal', manageCalLabel);
                break;
            case 'calculus':
                sendEvent('clickCalculus', calculusLabel);
                if (parseInt(profile.calculus) === 1) {
                    return window.open(endpoints.calculusUrl, '_blank');
                }
                setCalculusModal(true);
                break;
            default:
                break;
        }
    }

    async function onLogout() {
        try{
            var domainToBeCleared = process.env.REACT_APP_BASE_DOMAIN_NAME;
            if (
                window.location.href.includes('.tech') &&
                domainToBeCleared.includes('.com')
            ) {
                domainToBeCleared = domainToBeCleared.replace('.com', '.tech');
            }
            var token = process.env.REACT_APP_COOKIE_NAME;
            var oldToken = process.env.REACT_APP_OLD_COOKIE_NAME;
            var adminToken = `${process.env.REACT_APP_BASE_DOMAIN.toUpperCase()}_ADMIN`;
            
            if (domainToBeCleared) domainToBeCleared = '.' + domainToBeCleared;

            document
                .cookie
                .split(";")
                .forEach(function (c) {
                    let cName = c.split("=")[0];
                    if (cName)
                        document.cookie = `${cName}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${domainToBeCleared};path=/;`;
                });

            // document.cookie = `${token}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${domainToBeCleared};path=/;`;
            // document.cookie = `${oldToken}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${domainToBeCleared};path=/;`;
            // document.cookie = `${adminToken}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${domainToBeCleared};path=/;`;

            localStorage.removeItem('widget_interview');
            sessionStorage.removeItem('widget_interview_jobId');
            sessionStorage.removeItem('widget_interview_redirection');
            localStorage.removeItem('companyRegisterSuccess');
            localStorage.removeItem('feature-access');
            window.location.href = endpoints.landing;

            await logout(recruiterId);

        }
        catch(e) {
            console.log(e);
        }
        
    }

    const productDiv = (
        <div className="product-item product-item-signup navigation">
            <ul>
                <MenuItem item={productItems} currPage={(props.pathName == "login" || props.pathName == "login/") ? "landing" : (props.pathName && props.pathName.includes("contact-us")) ? "contact_us": ""}/>
            </ul>
        </div>
    );

    // using process.env.REACT_APP_DOMAIN for server side rendering purposes. This env variable
    // is being set in index.js of main app so that babel compiles.
    const logoDiv = (
        <div className="domain_logo header_item">
            <a
                onClick={() => sendEvent('viewDashboard', logoLabel)}
                href={endpoints.dashboard}
                className="logoLink"
            >
                {process.env.REACT_APP_BASE_DOMAIN !== 'hirist' ? (
                    <>
                        <h1>{process.env.REACT_APP_BASE_DOMAIN}</h1>
                        <span>.com</span>
                        <p className="logo_tagline"></p>
                    </>
                ) : (
                    <img
                        className={`hirist-tech-logo ${
                            useNewLandingDesign ? 'black-logo' : 'white-logo'
                        }`}
                        src="/static/images/hirist-tech-logo.webp"
                        alt="hirist-tech-logo"
                    />
                )}
            </a>
            {useNewLandingDesign ? productDiv : null}
        </div>
    );

    if (checkFeature && checkFeature('noMenu')) {
        return (
            <div className="header">
                <div className="header-wrapper">{logoDiv}</div>
                {
                    checkFeature('showLogoutBtn') ? 
                    <Button type="black" classes={["logout-btn"]} onClick={onLogout}>Logout</Button> : null
                }
            </div>
        );
    }

    const handleSignUp = () => {
        dispatch(actions.setActionType({ type: 'register_modal' }));
    };

    return (
        <div className='c'>
             {/* {typeof window === 'undefined' ? (
                    <div className="domain-migration-header-propmt-wrapper"></div>
                ) : checkFeature('domainMigrationPrompt1') ? (
                    <HeaderPropmpt />
                ) : (
                    <div className="domain-migration-header-propmt-wrapper"></div>
                )} */}
            <div
                className={`header ${useNewLandingDesign ? 'new' : ''}`}
                data-testid={useNewLandingDesign ? 'header-new' : ''}
            >
                {typeof window === 'undefined' ? (
                    <div className="domain-migration-header-propmt-wrapper"></div>
                ) : checkFeature('domainMigrationPrompt1') ? (
                    <HeaderPropmpt loadTalentCloudWidget = {loadTalentCloudWidget}/>
                ) : (
                    <div className="domain-migration-header-propmt-wrapper"></div>
                )}
                <div className="header-wrapper">
                    {!useNewLandingDesign ? (
                        <div
                            onClick={() => setShowNav(true)}
                            className="mobile-menu header_item mobileHeaderNav"
                        >
                            <img
                                alt="menu-icon"
                                src="/static/images/hamburger.svg"
                            />
                        </div>
                    ) : null}

                    {logoDiv}

                    {!useNewLandingDesign ? (
                        <>
                            {showSearch ? (
                                <div className={`search-container hidden animated fadeIn ${showTalentCloud ? "search-container-new": ""}`}>
                                    <form
                                        action=""
                                        onSubmit={submitSearch}
                                        id="globalSearch"
                                    >
                                        <input
                                            className="search-box"
                                            type="Text"
                                            autoFocus
                                            onChange={onChange}
                                            value={searchStr}
                                            placeholder="Search for candidates"
                                            autoComplete="off"
                                        />
                                    </form>
                                </div>
                            ) : !checkFeature('noHeaders') ? (
                                <div className="navigation header_item animated fadeIn">
                                    <ul className="menu-listing">
                                        {navItems.map((item, i) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    {!item.isHidden ||
                                                    showMenuItems[
                                                        item.title
                                                    ] ? (
                                                        <MenuItem
                                                            onClick={
                                                                item.onClick
                                                                    ? item.onClick(
                                                                          navItemClick,
                                                                      )
                                                                    : null
                                                            }
                                                            item={item}
                                                            navItemClick={
                                                                navItemClick
                                                            }
                                                        />
                                                    ) : null}
                                                </React.Fragment>
                                            );
                                        })}
                                        <li className="menu-list-item contact_us_menu_main_wrapper"
                                            onMouseEnter={() => setContactMenuOpen(true)}
				                            onMouseLeave={() => setContactMenuOpen(false)}
                                        >
                                            <ContactSales contactMenuOpen = {contactMenuOpen}/>
                                        </li>
                                        <li className="menu-list-item interview-calendar-header">
                                            <InterviewCalendar
                                                recruiterId={recruiterId}
                                            />
                                        </li>
                                    </ul>
                                </div>
                            ) : null}
                           
                            <div className="profile_actions header_item profileActions right">
                                {/* {showSearch ? null : (
                                    <div className="menu-list-item contact_info_toll">
                                        <div className="header-links">
                                            Sales Enquiries and many{' '}
                                            <span>(9:30 AM to 6:30 PM)</span>
                                            <div>
                                                <i className="icon-telephone"></i>
                                                1800-103-7344
                                            </div>
                                        </div>
                                    </div>
                                )} */}


                                {!showSearch && <SideMenu/>}
                                <div class="line-separation">
				                </div>

                                {checkFeature('accountSettings') ? (
                                    <>
                                        <div className="search action-icon searchIcon">
                                            {showSearch ? (
                                                <div
                                                    data-testid="close-search"
                                                    onClick={() =>
                                                        setShowSearch(false)
                                                    }
                                                    className="search-icon"
                                                >
                                                    <i className="icon-cross_icon"></i>
                                                </div>
                                            ) : (
                                                <div
                                                    data-testid="open-search"
                                                    onClick={() =>
                                                        setShowSearch(true)
                                                    }
                                                    className="search-icon"
                                                >
                                                    <i className="icon-search"></i>
                                                </div>
                                            )}
                                        </div>
                                        {checkFeature('chatFeatureBool') ? (
                                            <div className="messages action-icon msgAction">
                                                <a href={endpoints.chats}>
                                                    <div className="chat-icon">
                                                        <i className="icon-chat_bubble"></i>
                                                    </div>
                                                </a>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                ) : null}

                                {/* {showSearch ? null : ( */}
                                    {/* <ContactSales/> */}
                                {/* )} */}
                                
                                <UserProfile
                                    profile={profile}
                                    recruiterId={recruiterId}
                                    checkFeature={checkFeature}
                                    logout={onLogout}
                                />
                                <div id="talentCloud" class={`${showTalentCloud ?  "showTalentCloud": ""}`}></div>
                            </div>

                        </>
                    ) : (
                        <>
                            {!noSignUp ? (
                                <div className="profile-actions">
                                    <a
                                        className="btn-apply-job looking_for_a_job_text"
                                        href={`https://www.${process.env.REACT_APP_BASE_DOMAIN_NAME}`}
                                    >
                                        Looking for Job ?
                                    </a>
                                    <a
                                        className="btn-talk-sales"
                                        href={`${endpoints.requestCallback}?ref=landing`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={() => {
                                            const eventLabel = `origin=Login`;
                                            sendEvent(
                                                'clickTalkToSalesCTA',
                                                eventLabel,
                                            );
                                        }}
                                    >
                                        Talk to Sales
                                    </a>
                                    <Button
                                        classes={['btn-sign-up']}
                                        onClick={handleSignUp}
                                    >
                                        Sign Up
                                    </Button>
                                    <div
                                        onClick={() => setShowNavMobileSignup(true)}
                                        className="mobile-menu-signup header_item mobileHeaderNav"
                                    >
                                        <img
                                            alt="menu-icon"
                                            src="/static/images/hamburger_black.svg"
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </>
                    )}
                </div>
            </div>
            {!useNewLandingDesign && showNav ? (
                <MobileNavBar
                    profile={profile}
                    logoLabel={logoLabel}
                    navItemClick={navItemClick}
                    onClose={() => setShowNav(false)}
                    items={navItems}
                    logout={onLogout}
                />
            ) : null}
            {useNewLandingDesign && showNavMobileSignup ? (
                <MobileNavBarSignUp
                    logoLabel={logoLabel}
                    // navItemClick={navItemClick}
                    onClose={() => setShowNavMobileSignup(false)}
                    items={productItems}
                    // logout={onLogout}
                />
            ) : null}
            {!useNewLandingDesign && searchModal ? (
                <SearchModal
                    recruiterId={recruiterId}
                    onClose={() => {
                        setSearchModal(false);
                    }}
                />
            ) : null}
            {!useNewLandingDesign && calculusModal ? (
                <CalculusModal
                    recruiterId={recruiterId}
                    profile={profile}
                    onClose={() => {
                        setCalculusModal(false);
                    }}
                />
            ) : null}
            {
                (checkFeature('domainMigrationPrompt2')) ? (
                    <LoaderPropmpt recruiterId={recruiterId} />
                ) : null
            }
        </div>
    );
}

Header.propTypes = {
    useNewLandingDesign: PropTypes.bool,
};
